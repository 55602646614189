import { Stack } from "@mui/material";
import React from "react";

import pageNotFoundLogo from '../assets/page_not_found_logo.jpg';

export const PageNotFound = () => {
    return (
        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" height="60vh">
            <img src={pageNotFoundLogo} alt="Page Not Found" style={{ height: '300px' }} />
            <h1>404</h1>
            <h2>Page Not Found</h2>
        </Stack>
    )
}