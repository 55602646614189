
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './Auth.scss';
import { AuthService } from '../../services/auth.service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { environment } from '../../utils/utils';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';

export enum AuthMode {
    signup = 'signup',
    signin = 'signin'
}

export const Auth = () => {
    const [mode, setMode] = useState<AuthMode>(AuthMode.signin)

    if (mode === AuthMode.signin) {
        return (
            <SignInForm onSwitchToSignup={() => setMode(AuthMode.signup)} />
        )
    } else if (mode === AuthMode.signup) {
        return (
            <SignUpForm onSwitchToSignIn={() => setMode(AuthMode.signin)} />
        )
    }
}

export type SignInProps = {
    email?: string
    onSwitchToSignup: () => void
}

export type SignUpProps = {
    username?: string
    email?: string
    onSwitchToSignIn: () => void
}

type SignInFormData = {
    email: string;
    password: string;
}

const isProd = environment === 'production';

export const SignInForm = ({ email, onSwitchToSignup }: SignInProps) => {
    const [formdata, setformdata] = useState<SignInFormData>({
        email: '',
        password: ''
    })

    useEffect(() => {
        setTimeout(() => {
            initGSI();
        }, 1000)
    }, []);

    const [errors, setErrors] = useState<{ message: string }[]>([])
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const error = search.get('error');

    const navigate = useNavigate()

    const googleOnly = useMemo(() => search.get('method') === 'google', [search]);

    const doSignin = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setErrors([])

        AuthService.signin(formdata).then((user) => {
            console.log('Logged in: ', user);
            // navigate('/welcome');
        }, (e) => {
            setErrors([{ message: e.message }]);
        })
    }, [formdata])
    return (
        <form onSubmit={doSignin} className="auth-form">
            <Stack spacing={2} direction={'column'}>
                <h2>Sign in</h2>
                {
                    !isProd && <Alert severity="warning">This is the {environment} environment, you need to use your employee persera.ca email address to sign up or sign in</Alert>
                }
                <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                    <div id="g_id_signin" style={{ width: "100%" }}></div>
                </Stack>
                {
                    error && error === 'got' && <Alert severity="error">An error occurred trying to sign up with this Google account because we cannot determine the email address</Alert>
                }

                {!googleOnly && <>
                    <hr />
                    <TextField InputLabelProps={{ shrink: true }} name="email" label="email" variant="outlined" type="text" value={formdata.email} onChange={(e) => {
                        setformdata((f) => ({
                            ...f,
                            email: e.target.value
                        }))
                    }} />
                    <TextField InputLabelProps={{ shrink: true }} name="password" label="password" variant="outlined" type="password" value={formdata.password} onChange={(e) => {
                        setformdata((f) => ({
                            ...f,
                            password: e.target.value
                        }))
                    }} />

                    <Errors errors={errors} />
                    <Button type="submit" variant={'contained'}>Sign in</Button>
                    <Button onClick={onSwitchToSignup} variant={'outlined'} color={'info'}>Create an account</Button>
                    <Button variant={'text'} to="/forgot" component={Link}>Forgot password?</Button>
                </>}
            </Stack>
        </form>
    )
}

type SignUpFormData = {
    username: string;
    email: string;
    password: string;
    invite?: string;
}

export const SignUpForm = ({ username, email, onSwitchToSignIn }: SignUpProps) => {

    const [formdata, setformdata] = useState<SignUpFormData>({
        username: '',
        email: '',
        password: '',
        invite: ''
    })

    const [errors, setErrors] = useState<{ message: string }[]>([])


    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const error = search.get('error');
    const navigate = useNavigate();

    const googleOnly = useMemo(() => search.get('method') === 'google', [search]);


    useEffect(() => {
        setTimeout(() => {
            initGSI();
        }, 1000)
    }, []);

    const doSignup = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setErrors([])
        AuthService.signupByEmail(formdata).then((user) => {
            // navigate('/welcome')
        }, (e) => {
            setErrors([{ message: e.message }]);
        })
    }, [formdata])

    return (
        <form onSubmit={doSignup} className="auth-form">
            <Stack spacing={2} direction={'column'}>

                <h2>Sign up for an account</h2>
                {
                    !isProd && <Alert severity="warning">This is the {environment} environment, you need to use your employee persera.ca email address to sign up or sign in</Alert>
                }
                <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                    <div id="g_id_signin" style={{ width: "100%" }}></div>
                </Stack>
                {
                    !googleOnly && <>

                        <hr />
                        {
                            error && error === 'got' && <Alert severity="error">An error occurred trying to sign up with this Google account because we cannot determine the email address</Alert>
                        }
                        <TextField InputLabelProps={{ shrink: true }} autoComplete='off' name="email" label="email" variant="outlined" type="email" value={formdata.email} onChange={(e) => {
                            setformdata((f) => ({
                                ...f,
                                email: e.target.value
                            }))
                        }}
                            error={errors.some(e => e.message === 'Email is already taken' || e.message === 'Email cannot be empty')}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }} autoComplete='off' name="name" label="name" variant="outlined" type="text" value={formdata.username} onChange={(e) => {
                                setformdata((f) => ({
                                    ...f,
                                    username: e.target.value
                                }))
                            }}
                            error={errors.some(e => e.message === 'Name cannot be empty')}
                        />
                        <TextField InputLabelProps={{ shrink: true }} autoComplete='off' name="password" label="password" variant="outlined" type="password" value={formdata.password} onChange={(e) => {
                            setformdata((f) => ({
                                ...f,
                                password: e.target.value
                            }))
                        }} />
                        {/* 
                <TextField InputLabelProps={{ shrink: true }} autoComplete='off' name="invite" label="invite code (optional)" variant="outlined" type="text" value={formdata.invite} onChange={(e) => {
                    setformdata((f) => ({
                        ...f,
                        invite: e.target.value
                    }))
                }} /> */}

                        <Errors errors={errors} />

                        <Typography variant="body2">By signing up, you agree to our <a href="/terms" target="_blank">terms of service</a> and <a href="/privacy" target="_blank">privacy policy</a></Typography>
                        <Button type="submit" variant={'contained'}>Sign up</Button>
                        <Button variant={'text'} onClick={onSwitchToSignIn} color={'secondary'}>I have an account, sign in</Button>

                    </>
                }
            </Stack>
        </form>
    )
}

const Errors = ({ errors }: { errors: Array<{ message: string }> }) => {
    return (
        <List sx={{ color: 'error.main' }}>
            {
                errors.map((err, i) => {
                    return (
                        <ListItem key={i}>
                            <Typography>{err.message}</Typography>
                        </ListItem>
                    )
                })
            }
        </List>)
}

function initGSI() {
    window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        { theme: "outline", size: "large" }  // customization attributes
    );
}