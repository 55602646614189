

import React from 'react';
import { useAuth } from '../services/useAuth';
import { UsersList } from '../components/UsersList/UsersList';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export const AccountsAdminPage = () => {

    const { user, loading } = useAuth();

    if (loading) {
        return <Typography variant="h3">Loading...</Typography>
    }
    if (!user) {
        return <Typography variant="h3">You are not logged in</Typography>
    }
    if (!user.isAdmin) {
        return <Typography variant="h3">You are not authorized to view this page</Typography>
    }


    return (
        <Container>
            <Stack direction="column" spacing={2}>
                <Typography variant="h3">Accounts Admin Page</Typography>
                <UsersList />
            </Stack>
        </Container>
    )
}