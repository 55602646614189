import Container from '@mui/material/Container/Container';
import React from 'react';


export const CustomerSupport = () => {

    return (
        <Container>
            <h1>Customer Support</h1>
            <p>
                If you need assistance, please contact our customer support team at <a href="mailto:admin@persera.ca">admin@persera.ca</a>.
            </p>
            <p>
                Refunds on subscriptions are at the discretion of the customer support team.
            </p>
        </Container>
    )
}