import React from 'react';
import { useAuth } from '../../services/useAuth';
import { Link, useNavigate } from 'react-router-dom';

import "./Header.scss"
import { AuthService } from '../../services/auth.service';
import logo from './../../assets/persera_48.png';
import { environment } from '../../utils/utils';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Button from '@mui/material/Button/Button';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';

export default function Header() {

    const { user, loading } = useAuth();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    const logout = () => {
        AuthService.logout().then(() => {
            setAnchorEl(null);
            navigate('/');
        });
    }

    return (
        <div className="header">
            <Container className="header-container">
                <Link to="/" style={{
                    textDecoration: 'none',
                    color: 'black'
                }}>
                    <Stack direction="row" alignItems="center" justifyContent="flex=start" spacing={1}>
                        <img src={logo} alt="Persera Logo" />
                        <h1>Persera {environment === 'development' && "Development"} {environment === "staging" && "Staging"}</h1>
                    </Stack>
                </Link>
                <span style={{ flexGrow: 1 }}></span>
                {
                    loading && <CircularProgress />
                }
                {
                    (!loading && !!user) && <>
                        <Button id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>{user.username}</Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => {
                                handleClose();
                                navigate('/manage-account');
                            }}>Manage Account</MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </>
                }
                {
                    !loading && !user && (
                        <Button onClick={() => navigate('auth')}>Login/Register</Button>
                    )
                }
            </Container>

        </div>
    )
}