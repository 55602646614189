import React from 'react';

import { BrowserRouter as Router, Route, Routes, ScrollRestoration, useNavigate, BrowserRouter, createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import Home from './pages/Home';
import './App.scss';
import { Welcome } from './pages/Welcome';
import AuthPage from './pages/AuthPage';
import Header from './components/Header/Header';
import theme from './theme';
import GoogleOneTapLogin from 'react-google-one-tap-login';

import { Provider } from 'jotai';
import { store } from './services/main.service';
import { Impressum } from './pages/Impressum';
import { Privacy } from './pages/Privacy';
import { About } from './pages/About';
import { Footer } from './components/Footer/Footer';
import { CookieBanner } from './components/CookieBanner/CookieBanner';
import { ManageAccount } from './pages/ManageAccount';
import { ChangePassword } from './components/AccountManagement/Security/ChangePassword';
import { PrivateRoutes } from './utils/PrivateRoutes';
import { CustomerSupport } from './pages/CustomerSupport';
import { TermsOfService } from './pages/TermsOfService';
import { PageNotFound } from './utils/PageNotFound';
import { CareersPage } from './pages/Careers';
import { CookiesPage } from './pages/Cookies';
import { CheckoutPage } from './pages/Checkout';
import { ForgotPassword } from './pages/ForgotPassword';
import { AdminRoutes } from './utils/AdminRoutes';
import { AdminLandingPage } from './adminPages/AdminLandingPage';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Container from '@mui/material/Container/Container';
import { AccountsAdminPage } from './adminPages/AccountsAdminPage';
import { UserAdminPage } from './adminPages/UserAdminPage';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';


const Root = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <Header />
                        <Container>
                            <Outlet />
                        </Container>
                        <Footer />
                        <CookieBanner />
                    </div>
                    <ScrollRestoration />
                </ThemeProvider>
            </Provider>
        </LocalizationProvider>
    )
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                element: <PrivateRoutes />,
                children: [
                    {
                        path: '/manage-account',
                        element: <ManageAccount />
                    },
                    {
                        path: '/change-password',
                        element: <ChangePassword />
                    },
                    {
                        path: '/welcome',
                        element: <Welcome />
                    },
                    {
                        path: '/checkout',
                        element: <CheckoutPage />
                    }
                ]
            },
            {
                element: <AdminRoutes />,
                children: [
                    {
                        path: '/admin',
                        element: <AdminLandingPage />
                    },
                    {
                        path: '/admin/users',
                        element: <AccountsAdminPage />
                    },
                    {
                        path: '/admin/user/:userId',
                        element: <UserAdminPage />
                    }
                ]
            },
            {
                path: '/login',
                element: <AuthPage />
            },
            {
                path: '/auth',
                element: <AuthPage />
            },
            {
                path: '/forgot',
                element: <ForgotPassword />
            },
            {
                path: '/impressum',
                element: <Impressum />
            },
            {
                path: '/privacy',
                element: <Privacy />
            },
            {
                path: '/about',
                element: <About />
            },
            {
                path: '/support',
                element: <CustomerSupport />
            },
            {
                path: '/terms',
                element: <TermsOfService />
            },
            {
                path: '/careers',
                element: <CareersPage />
            },
            {
                path: '/investors',
                element: <div>Investors</div>
            },
            {
                path: '/cookies',
                element: <CookiesPage />
            },
            {
                path: '*',
                element: <PageNotFound />
            }
        ]
    },

])


export default function App() {

    return (
        <RouterProvider router={router} />
    )
}