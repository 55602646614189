import React from 'react';

import './Home.scss';

export default function Home() {
    return (
        <div className="home">
            <div className="hero-image">

            </div>
            <div className="site-content">
                <h2>Welcome!</h2>
                <br />
                <p>
                    At Persera, we empower individuals to bring focus and clarity to their lives. Our suite of apps and services are designed to help you organize your life, career, and family. We believe that by providing the right tools and resources, you can achieve your goals and live a fulfilling life.
                </p>
                <h3>Asian Dad Workbooks</h3>
                <p>
                    Grow academically and professionally with our workbooks. Asian Dad Workbooks provide tools to master vocabulary and mathematics with exerices for all levels.
                </p>
                {/* <h3>Career Prep</h3>
                <p>
                    Organizing your career has never been so clear. With Career Prep, you can tailor your resume, track your job applications, and prepare for interviews. Our AI-powered tools help you present your best self to prospective employers.
                </p> */}
                <h3>Hack in a Box</h3>
                <p>
                    Hack in a Box is a tool to help organize your next hackathon - invite participants, submit ideas, join teams, and vote on your favorite projects!
                </p>
                <h3>Pomogram</h3>
                <p>
                    Coming soon! Pomogram is a productivity tool to help you focus on your tasks and achieve your goals. Stay tuned for updates.
                </p>
            </div>

        </div>
    )
}