import React, { useEffect } from 'react';
import { useAuth } from '../services/useAuth';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Subscriptions } from '../components/Subscriptions/Subscriptions';
import { Apps } from '../services/appSub.service';
import { map } from 'lodash';
import Stack from '@mui/material/Stack/Stack';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Button from '@mui/material/Button/Button';


export const Welcome = () => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if (!user && !loading) {
            navigate('/auth')
        }
    }, [user, loading])

    if (loading) {
        return <CircularProgress />
    }
    return (
        <Stack direction="column" spacing={2}>

            <h2>Welcome! {user?.username}</h2>
            <Stack direction="row" spacing={2}>
                {
                    map(Apps, (app) => {
                        return (<Card key={app.appId}>
                            <CardContent>
                                <h3>{app.label}</h3>
                                <p>{app.description}</p>
                                {app.url && <Button href={app.url}>Go</Button>}
                            </CardContent>
                        </Card>)
                    })
                }
            </Stack>

        </Stack>
    )
}