import React, { useState, useEffect } from 'react';
import { Account, User } from '../types/Account';
import { useAuth } from '../services/useAuth';
import { useParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdminService } from '../services/admin.service';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Container from '@mui/material/Container';

export const UserAdminPage = () => {
    const [account, setAccount] = useState<Account | null>(null);
    const { user, loading } = useAuth();
    const { userId } = useParams();

    useEffect(() => {
        if (!userId) {
            return;
        }
        // if the userId exists, try to fetch the user details
        AdminService.getAccount(userId).then((data) => {
            setAccount(data);
        })
    }, [])

    if (!userId) {
        return <h1>User ID not provided</h1>
    }
    if (loading) {
        return <h1>Loading...</h1>
    }
    if (!user) {
        return <h1>You are not logged in</h1>
    }
    if (!user.isAdmin) {
        return <h1>You are not authorized to view this page</h1>
    }

    if (!account) {
        return <h1>User not found</h1>
    }
    return (
        <Container>
            <h1>User Admin Page</h1>
            <h2>{account.username}</h2>
            <p>{account.email}</p>
            <p>{account.guid}</p>
            <h2>Subscriptions</h2>


            <DataGrid
                rows={account.subscriptions || []}
                columns={[
                    { field: 'appId', headerName: 'App ID', width: 150 },
                    { field: 'appName', headerName: 'App Name', width: 200 },
                    {
                        field: 'expiryDate',
                        headerName: 'Expiry Date',
                        width: 200,
                        renderCell: (params) => (
                            <DatePicker
                                value={params.value ? dayjs(params.value) : null}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        // Update the subscription expiry date
                                        AdminService.updateSubscription(account.guid, params.row.appId, {
                                            expiryDate: newValue.toISOString()
                                        }).then(() => {
                                            // Refresh the subscriptions list
                                            AdminService.getAccount(userId).then((data) => {
                                                setAccount(data);
                                            })
                                        });
                                    }
                                }}
                            />
                        )
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        width: 150,
                        editable: true,
                        type: 'singleSelect',
                        valueOptions: ['active', 'expired', 'cancelled', 'pending']
                    },
                    {
                        field: 'notes',
                        headerName: 'Notes',
                        width: 250,
                        editable: true
                    }
                ]}

                disableRowSelectionOnClick
                getRowId={(row) => row.appId}
            />
        </Container>
    )
}