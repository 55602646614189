import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../services/useAuth';
import Container from '@mui/material/Container';

export const AdminRoutes = () => {
    const { user, loading, isAdmin } = useAuth()

    if (loading) {
        return <></>
    }

    if (!user || !isAdmin) {
        return <Container className="site-content">
            <h1>Unauthorized</h1>
            <p>You must be logged in as an Admin to access this page.</p>
            <Link to={`/login?cont=${window.location.href}`}>Login</Link>
        </Container>
    }

    return (
        <Outlet />
    )
}