import React, { useEffect, useMemo, useState } from 'react';
import { AuthService } from './auth.service';

import { useAtomValue } from 'jotai';
import { User } from '../types/Account';
export const useAuth = () => {

    const user = useAtomValue(AuthService.atom);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        AuthService.checkProfile().then((_user: User) => {
            setLoading(false);
        }, () => {
            setLoading(false);
        })
    }, [])

    const isAdmin = useMemo(() => {
        return user?.isAdmin
    }, [user])
    return {
        user,
        loading,
        isAdmin
    }
}