
import Box from '@mui/material/Box/Box';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import React from 'react';

export const CareersPage = () => {
    return (
        <Container>
            <Typography variant="h4">Careers</Typography>

            <Stack direction="column" spacing={2}>
                <Box>
                    <Typography variant="body1">
                        No jobs listed at the moment, check back again!
                    </Typography>
                </Box>
            </Stack>
        </Container>
    )
}