import axios from "axios"
import { atom } from "jotai";
import { store } from "./main.service";
import { environment } from "../utils/utils";
import { AppSub } from "../types/Account";
/**
 * AppService
 * 
 * Organizes information about each of the Apps on dsolver.ca
 */
interface AppRecord {
    subdomain: string;
    appId: string;
    label: string;
    description?: string;
    url?: string;
    development?: string;
    staging?: string;
    production?: string;
}

const AsianDadWorkbooks: AppRecord = {
    subdomain: 'workbooks',
    appId: 'asian-dad-workbooks',
    label: 'Asian Dad Workbooks',
    description: 'Arithmetic and Language exercises.',
    development: 'http://localhost:4001',
    staging: 'https://workbooks.dsolver.ca',
    production: 'https://workbooks.persera.ca',
}

AsianDadWorkbooks.url = AsianDadWorkbooks[environment];

export const Apps: { [key: string]: AppRecord } = {
    'asian-dad-workbooks': AsianDadWorkbooks,
    // 'federation-manager': {
    //     subdomain: 'federation-manager',
    //     appId: 'federation-manager',
    //     label: 'Module Federation Manager',
    //     description: "Coming soon"
    // },
    'career-prep': {
        subdomain: 'career-prep',
        appId: 'career-prep',
        label: 'Career Prep',
        description: "Coming soon",
    },
    'pomogram': {
        subdomain: 'pomogram',
        appId: 'pomogram',
        label: 'Pomogram',
        description: "Coming soon"
    }
}

console.assert(Object.keys(Apps).every(key => Apps[key].appId === key), 'Every App must have an app-id that matches its key');

export const AppService = {
    getApp: (name: string) => {
        if (!Apps[name]) {
            throw new Error('No known app: ' + name)
        }
        return Apps[name]
    }
}

export interface Account {

}

const appSubAtom = atom<AppSub[] | null>(null);
const accountsAtom = atom<Account[]>([]);

export class AppSubService {

    static get store() {
        return store;
    }

    static get atom() {
        return appSubAtom;
    }

    private static client = axios.create({
        baseURL: '/api'
    })

    static async listSubscriptions() {
        const { data } = await this.client.get('/subscriptions')

        const subs = data as AppSub[];

        store.set(appSubAtom, subs);

        return subs;
    }

    static async addSub(appId: string) {
        await this.client.post(`/subscription/app/${appId}`);
        return this.listSubscriptions();
    }

    static payloadToAppSub(payload: any): AppSub {
        return {
            appId: payload.appId,
            subscriptionId: payload.subscriptionId,
            lastUpdated: new Date(payload.lastUpdated),
            cancelledDate: new Date(payload.cancelledDate),
            expiryDate: new Date(payload.expiryDate),
            isTrial: payload.isTrial,
            active: payload.active,
            cancelled: payload.cancelled,
            stripeSubscriptionId: payload.stripeSubscriptionId
        }
    }
}