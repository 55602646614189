import { AuthService } from "./auth.service";

export function initOneTap() {
    window.onload = () => {
        setTimeout(() => {
            // check if the user is already logged in
            const isLoggedIn = !!AuthService.user;

            if (!isLoggedIn) {
                window.google.accounts.id.initialize({
                    client_id: "675814892023-4d7sfl5ae6e0rvpfgo7hk5ioakem20sv.apps.googleusercontent.com",
                    callback: (response: any) => {
                        console.log(response);
                        if (response.credential) {
                            AuthService.signinOneTap(response.credential).then((user) => {
                                console.log('Logged in: ', user);
                            }, (e) => {
                                console.error(e);
                            })
                        }
                    }
                })
                window.google.accounts.id.prompt((notification: any) => {
                    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                        // try next provider if OneTap is not displayed or skipped
                        console.log("OneTap not displayed or skipped");
                    }
                });
            }
        }, 2000)

    }
}