import React, { useEffect } from 'react';
import { redirect, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../services/useAuth';
import { Auth } from '../components/Auth/Auth';

import logoAsianDadWorkbooks from '../assets/asian_dad_workbooks.jpg';
import logoHackInABox from '../assets/hack-in-a-box.png';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';

export default function AuthPage() {
    const { user, loading } = useAuth();

    const [queryParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
        if (user && !loading) {
            const contUrl = queryParams.get('cont')
            // client side redirect
            if (contUrl) {
                window.location.href = (contUrl)
            } else {
                navigate('/welcome');
            }
        }
    }, [user, loading, queryParams])

    if (loading) {
        return <CircularProgress />
    }
    return (
        <Container>
            <Stack direction="column" spacing={2} alignItems={'center'} justifyContent={'center'}>
                {
                    queryParams.get('app') && (
                        <div className="auth-form">
                            <AppBlurb app={queryParams.get('app')} />
                        </div>
                    )
                }
                <Auth />
            </Stack>
        </Container>
    )
}

const AppBlurb = ({ app }: { app: string | null }) => {

    switch (app) {
        case 'asian-dad-workbooks':
            return (<Stack direction="row" spacing={1}>
                <img src={logoAsianDadWorkbooks} alt="Asian Dad Workbooks" style={{ height: '48px' }} />
                <Typography component={'p'}>
                    Please sign in or create a Persera account to use <b>Asian&nbsp;Dad&nbsp;Workbooks</b>
                </Typography>
            </Stack>)
        case 'hack-in-a-box':
            return (<Stack direction="row" spacing={1}>
                <img src={logoHackInABox} alt="Hack in a Box" style={{ height: '48px' }} />
                <Typography component={'p'}>
                    Please sign in or create a Persera account to use <b>Hack&nbsp;in&nbsp;a&nbsp;Box</b>
                </Typography>
            </Stack>)
        default:
            return <></>
    }
}