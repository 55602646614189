// bootstraps the react application
import React from 'react';

import App from './App';

import { createRoot } from 'react-dom/client';

import './index.scss';
import { initOneTap } from './services/onetap.service';

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);
root.render(<App />);

initOneTap();
