import Box from '@mui/material/Box/Box';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import React from 'react';
import { Link } from 'react-router-dom';

// a 3-column footer with links to the company's pages

export const Footer = () => {

    return (
        <Box sx={{ backgroundColor: '#f0f0f0', padding: 4, marginTop: 4 }}>

            <Container >
                <Stack direction="row" spacing={3} justifyContent="center" alignItems="flex-start" paddingBottom={8}>
                    <Box sx={{ minWidth: '15rem' }}>
                        <h3>Products</h3>
                        <br />
                        <p>
                            <a href="https://workbooks.persera.ca">Asian Dad Workbooks</a><br />
                            {/* <a href="https://careerprep.persera.ca">Career Prep</a><br /> */}
                            {/* <a href="https://pomogram.persera.ca">Pomogram</a> */}
                            <a href="https://hackathons.persera.ca">Hack in a Box</a>
                        </p>
                    </Box>
                    <Box sx={{ minWidth: '15rem' }}>
                        <h3>Support</h3>
                        <br />
                        <p>
                            <Link to="/support">Customer Support</Link><br />
                            <Link to="/privacy">Privacy</Link><br />
                            <Link to="/terms">Terms and Conditions</Link><br />
                            <Link to="/cookies">Cookies & CCPA preferences</Link>
                        </p>
                    </Box>
                    <Box sx={{ minWidth: '15rem' }}>
                        <h3>Company</h3>
                        <br />
                        <p>
                            <Link to="/impressum">Impressum</Link><br />
                            {/* <Link to="/careers">Careers</Link><br /> */}

                        </p>
                    </Box>

                </Stack>
            </Container >
        </Box>
    )
}