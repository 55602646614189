import Container from '@mui/material/Container/Container';
import React from 'react';


export const TermsOfService = () => {

    return (
        <Container className="site-content">
            <h1>Terms of Service</h1>

            <p>Welcome to persera.ca!</p>
            <p>By accessing and using our services, you agree to comply with the following terms and conditions:</p>
            <ol>
                <li>Use of our services is subject to your acceptance of these terms of service.</li>
                <li>You must be at least 18 years old to use our services.</li>
                <li>You are solely responsible for maintaining the confidentiality of your account and password.</li>
                <li>You agree not to use our services for any illegal or unauthorized purpose.</li>
                <li>We reserve the right to modify or terminate our services at any time without prior notice.</li>
                <li>We may collect and use your personal information in accordance with our privacy policy.</li>
                <li>Our services may include links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.</li>
                <li>By using our services, you agree to indemnify and hold harmless persera.ca and its affiliates from any claims, damages, or losses arising out of your use of our services or violation of these terms of service.</li>
                <li>These terms of service shall be governed by and construed in accordance with the laws of your jurisdiction.</li>
                <li>If any provision of these terms of service is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</li>
            </ol>
            <p>Thank you for using persera.ca and its suite of apps!</p>
        </Container>
    )
}