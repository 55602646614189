
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export const AdminLandingPage = () => {

    return (
        <Container>
            <Stack direction="column" spacing={2}>
                <Typography variant="h3">Admin Landing Page</Typography>
            </Stack>
        </Container>
    )
}