import React from 'react';

import { useAuth } from '../../../services/useAuth';
import { AuthService } from '../../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography/Typography';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';

export const ChangePassword = () => {

    const { user, loading } = useAuth();

    const [oldPassword, setOldPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [newPasswordRepeat, setNewPasswordRepeat] = React.useState<string>('');

    const navigate = useNavigate();

    if (loading) {
        return <CircularProgress />
    }

    if (!user) {
        return <Typography variant="h3">You are not logged in</Typography>
    }

    const validateAndSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword && newPassword === newPasswordRepeat) {

            AuthService.changePassword({
                oldPassword,
                newPassword
            }).then(() => {
                setOldPassword('');
                setNewPassword('');
                setNewPasswordRepeat('');
                navigate('/manage-account');
            });
        }
    }
    return (
        <Container>
            <Stack direction="column" spacing={2} maxWidth={'100%'} width={'40rem'} margin={'auto'} alignItems={'center'} justifyContent={'flex-start'}>
                <Typography variant="h4" width={'100%'}>Change Password</Typography>
                <form onSubmit={validateAndSubmit} style={{ width: '100%' }}>
                    <Stack direction="column" spacing={2}>
                        <TextField label="Old Password" type="password" value={oldPassword} onChange={(e) => {
                            setOldPassword(e.target.value);
                        }} />

                        <TextField label="New Password" type="password" value={newPassword} onChange={(e) => {
                            setNewPassword(e.target.value);
                        }} />

                        <TextField label="Repeat New Password" type="password" value={newPasswordRepeat} onChange={(e) => {
                            setNewPasswordRepeat(e.target.value);
                        }} />
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" color="primary" type="submit">Change Password</Button>
                            <Button variant="contained" color="primary" onClick={() => {
                                navigate('/manage-account');
                            }}>Cancel</Button>
                        </Stack>
                    </Stack>
                </form>
            </Stack>

        </Container>
    )
}