
import Container from '@mui/material/Container/Container';
import React from 'react';

export const Impressum = () => {

    return (
        <Container>
            <h3>Contact</h3>
            <br />
            <p>
                <h4>Corporate Address:</h4>
                <b>Persera Software Inc.</b><br />
                235 William St.<br />
                Elmira, ON <br />
                Canada<br />
                N3B 0B4
            </p>

            <p>
                <h4>Contact information:</h4>
                Telephone: <a href="tel:+1-226-791-8135">+1-226-791-8135</a><br />
                Email: <a href="mailto:admin@persera.ca">admin@persera.ca</a><br />
                Website: <a href="https://www.persera.ca">https://www.persera.ca</a>
            </p>

            <p>
                <h4>Disclaimer:</h4>
                <b>Content Liability</b><br />
                The contents of our pages were created with great care. However, we cannot guarantee the contents' accuracy, completeness, or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this context, please note that we are not obliged to monitor the transmitted or saved information of third parties or investigate circumstances pointing to illegal activity.
            </p>
        </Container>
    )
}