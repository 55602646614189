import axios from 'axios';
import { AppSub, AppSubPayload, Account, UserCoreWithSubscriptions } from '../types/Account';
import dayjs from 'dayjs';

const api = axios.create({
    baseURL: '/api'
});




export interface AccountsPayload {
    results: UserCoreWithSubscriptions[],
    total: number,
    totalPages: number,
    page: number,
    resultsPerPage: number
}

export const parseAccount = (user: any): Account => {
    return {
        guid: user.guid,
        username: user.username,
        email: user.email,
        isAdmin: user.isAdmin,
        links: user.links,
        subscriptions: user.subscriptions.map((sub: AppSub) => {
            return {
                ...sub,
                lastUpdated: dayjs(sub.lastUpdated),
                cancelledDate: sub.cancelledDate ? dayjs(sub.cancelledDate) : null,
                expiryDate: dayjs(sub.expiryDate),
                isTrial: sub.isTrial,
                fetchedTimestamp: Date.now(),
                subscribed: true,
            }
        })
    }
}

export const AdminService = {

    getAccounts: async (page?: number, resultsPerPage?: number) => {
        return api.get('/admin/all-accounts', {
            params: {
                page: page || 0,
                resultsPerPage: resultsPerPage || 20
            }
        });
    },
    getAccount: async (userId: string): Promise<Account> => {
        const { data } = await api.get(`/admin/user/${userId}`);

        const user = parseAccount(data);
        return user;
    },
    grantSubscription: async (userId: string, appId: string, expiry: string) => {
        return api.post('/admin/grant-subscription', { userId, appId, expiry });
    },
    updateSubscription: async (userId: string, appId: string, subscriptionPartial: Partial<AppSubPayload>) => {
        return api.post('/admin/update-subscription', { userId, appId, subscriptionPartial });
    }
}