import { atom, useAtom } from 'jotai';
import { last } from 'lodash';
import React, { useEffect } from 'react';

const lastSavedPolicyStr = localStorage.getItem('cookie-policy');
const lastSavedBannerDisplayed = localStorage.getItem('cookie-banner-displayed');

export const cookieBannerAtom = atom(!lastSavedBannerDisplayed);

export type CookiePolicy = {
    performance: boolean;
    functional: boolean;
}

export const useCookies = () => {

    const [policy, setPolicy] = React.useState<CookiePolicy>(lastSavedPolicyStr ? JSON.parse(lastSavedPolicyStr) : {
        performance: true,
        functional: true
    });

    const [showCookieBanner, setShowCookieBanner] = useAtom(cookieBannerAtom);

    return {
        policy,
        showCookieBanner,
        acceptAll: () => {
            localStorage.setItem('cookie-policy', JSON.stringify({ performance: true, functional: true }));
            setPolicy({ performance: true, functional: true });
            setShowCookieBanner(false);
        },
        necessaryOnly: () => {
            localStorage.setItem('cookie-policy', JSON.stringify({ performance: false, functional: false }));
            setPolicy({ performance: false, functional: false });
            setShowCookieBanner(false);
        },
        setPolicy: (policy: CookiePolicy) => {
            localStorage.setItem('cookie-policy', JSON.stringify(policy));
            setPolicy({ ...policy });
            setShowCookieBanner(false);
        },
        closeBanner: () => {
            localStorage.setItem('cookie-banner-displayed', 'true');
            setShowCookieBanner(false);
        }
    }
}