import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { AppSubService } from '../services/appSub.service';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography/Typography';


const apps: {
    [key: string]: {
        name: string,
        productId: string,
        label: string,
        url: string
    }
} = {
    'asian-dad-workbooks': {
        name: 'Asian Dad Workbooks',
        productId: 'prod_QE9xRYsNu1yMDO',
        label: '$5/month',
        url: 'https://workbooks.persera.ca'
    }
}

export const CheckoutPage = () => {

    const [queryParams] = useSearchParams();

    const app = queryParams.get('app');

    const [purchased, setPurchased] = useState(false);
    useEffect(() => {
        let timer: number;
        const check = () => {
            AppSubService.listSubscriptions().then(subs => {
                if (subs.some(sub => sub.appId === app && sub.stripeSubscriptionId !== '')) {
                    setPurchased(true);
                } else {
                    timer = setTimeout(check, 1000);
                }

            }, error => { console.log(error) })
        }
        check();
        return () => {
            clearTimeout(timer);
        }
    }, [])

    if (!app) {
        return (<Container>
            You're not buying anything.
        </Container>)
    }

    const appData = apps[app];

    if (!appData) {
        return (<Container>
            Nope, can't buy that.
        </Container>)
    }


    const submitCheckout = () => {
        axios.post('/api/stripe/create-checkout-session', {
            product: appData.productId,
            app: app
        }).then(response => {
            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            }
        });
    }

    const cancelled = queryParams.get('canceled');
    const success = queryParams.get('success');

    if (purchased) {
        return (
            <Container>
                <Stack spacing={2} alignItems="center">
                    <Alert severity="success">Thank you for your paid subscription to {appData.name}!</Alert>
                    <Button variant="contained" color="primary" href={appData.url}>Go to {appData.name}</Button>
                </Stack>
            </Container>
        )
    }

    if (success && !purchased) {
        return (
            <Container>
                <Alert severity="success">Transaction successful</Alert>
                <Stack spacing={2} alignItems="center">
                    <CircularProgress />
                    <Typography>Sit tight, we are getting your subscription ready</Typography>
                </Stack>
            </Container>
        )
    }
    return (
        <Container>
            {
                cancelled && (
                    <Alert severity="error">Transaction cancelled</Alert>
                )
            }
            <h1>{appData.name}</h1>

            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                submitCheckout();
            }}>
                <Button type="submit" variant="contained" color="primary">{appData.label}</Button>
            </form>
        </Container>
    )
}