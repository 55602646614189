
import React, { useEffect, useState } from 'react';
import { SubscriptionSummary, User, UserCoreWithSubscriptions } from '../../types/Account';
import { AdminService } from '../../services/admin.service';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { Paged } from '../../types/Paged';
import Container from '@mui/material/Container';

export const UsersList = () => {

    const [accountsData, setAccountsData] = useState<Paged<UserCoreWithSubscriptions> | null>(null)

    useEffect(() => {
        AdminService.getAccounts().then((res) => {
            setAccountsData(res.data);
        })
    }, [])
    return (
        <Container>
            <h1>Users List</h1>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={accountsData?.results || []}
                    columns={[
                        { field: 'guid', headerName: 'Guid', width: 150, renderCell: (params) => <a href={`/admin/user/${params.value}`}>{params.value}</a> },
                        { field: 'email', headerName: 'Email', width: 250 },
                        { field: 'username', headerName: 'Username', width: 200 },
                        {
                            field: 'subscriptions', headerName: 'Subscriptions', width: 450, renderCell: (params) => {
                                return <div>{params.value.map((sub: SubscriptionSummary) => {
                                    return <div key={sub.appId}>{sub.appId} - {sub.expiryDate.toString()}</div>
                                })}</div>
                            }
                        }
                    ]}
                    pagination
                    paginationMode="server"
                    rowCount={accountsData?.total || 0}
                    paginationModel={{
                        pageSize: accountsData?.resultsPerPage || 10,
                        page: accountsData?.page || 0,
                    }}
                    onPaginationModelChange={(model) => {
                        AdminService.getAccounts(model.page, model.pageSize).then((res) => {
                            setAccountsData(res.data);
                        });
                    }}
                    getRowId={(row) => row.guid}
                />
            </div>

        </Container>
    )
}