
import Container from '@mui/material/Container';
import React, { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthService } from '../services/auth.service';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';


export const ForgotPassword = () => {

    const [queryParams] = useSearchParams();
    const [requestEmail, setRequestEmail] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [resetLoading, setResetLoading] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    const code = queryParams.get('code')

    const codeFields: null | { code: string, email: string } = useMemo(() => {
        if (!code) {
            return null;
        }
        try {
            return JSON.parse(atob(code!));
        } catch (e) {
            console.warn('Error ', e);
            return null;
        }
    }, [code])

    const sendPasswordResetLink = () => {
        if (!requestEmail) {
            setError("Please enter an email address");
            return;
        }
        // send the email to the server
        AuthService.forgotPasswordRequest(requestEmail).then(() => {
            setRequestSent(true);

        }).catch((e) => {
            setError(e.message);
        });
    };

    const tryResetPassword = () => {
        if (!codeFields) {
            setError("Invalid code");
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        setResetLoading(true);
        AuthService.forgotPasswordConfirm(codeFields!.code, newPassword).then(() => {
            setResetLoading(false);
            setResetSuccess(true);
        }).catch((e) => {
            setError("Something went wrong with changing your password, did you use the link in your email?");
            setResetSuccess(false);
            setResetLoading(false);
        });

    };

    if (resetSuccess) {
        return (
            <Container>
                <Stack spacing={2} direction="column">
                    <Typography variant="h4">Password Reset</Typography>
                    <Typography>Your password has been reset. You can now <a href="/login">sign in</a> with your new password</Typography>
                </Stack>
            </Container>
        )
    }

    if (!queryParams.has('code') || !codeFields) {
        // user is starting the forgot password process
        return (
            <Container>
                <form onSubmit={(e) => { e.preventDefault(); sendPasswordResetLink(); }}>
                    <Stack spacing={2} direction="column" justifyContent="flex-start" maxWidth="50rem" margin="auto">
                        <Typography variant="h4">Forgot Password</Typography>
                        <Typography variant="body1">Enter your email address to receive a password reset link</Typography>
                        {
                            !requestSent && (<>
                                <TextField
                                    label="Email"
                                    type="email"
                                    value={requestEmail}
                                    onChange={(e) => setRequestEmail(e.target.value)} />
                                <Stack direction="row">

                                    <Button variant="contained" onClick={sendPasswordResetLink}>Send Reset Link</Button>
                                </Stack>
                            </>)

                        }
                        {
                            requestSent && (<Typography>Check your email ({requestEmail}) for a password reset link</Typography>)
                        }
                        {
                            error && (<Typography color="error">{error}</Typography>)
                        }

                    </Stack>
                </form>
            </Container>
        );
    }

    return (
        <Container>
            <form onSubmit={(e) => { e.preventDefault(); tryResetPassword(); }}>
                <Stack spacing={2} direction="column">
                    <Typography variant="h4">Reset Password</Typography>
                    <Typography variant="body1">Hey {codeFields.email}, enter new password below to change it for your account.</Typography>
                    <TextField
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => { setNewPassword(e.target.value) }} />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                    />
                    <Stack direction={'row'}>
                        <Button variant="contained" type="submit">Reset Password</Button>
                    </Stack>

                    {
                        error && (<Typography color="error">{error}</Typography>)
                    }
                </Stack>
            </form>
        </Container>
    );
};
