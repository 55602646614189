import React, { useState } from 'react';

import { useCookies } from '../utils/useCookies';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import Switch from '@mui/material/Switch/Switch';
import Button from '@mui/material/Button/Button';
import Alert from '@mui/material/Alert/Alert';

export const CookiesPage = () => {

    const {
        policy,
        acceptAll,
        necessaryOnly,
        setPolicy
    } = useCookies();

    const [nextPolicy, setNextPolicy] = useState(policy);

    const markConfirmed = () => {
        localStorage.setItem('cookie-banner-displayed', 'true');
    }

    const [showAlert, setShowAlert] = useState(false);

    const flashAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    return (
        <Container>

            <Stack direction="column" spacing={2}>
                <Typography variant="h4">Cookies</Typography>
                <Typography variant="body1">
                    We use cookies to ensure you get the best experience on our website. By continuing to use our website, you agree to our cookie policy.
                </Typography>
                <List sx={{ maxWidth: "40rem", width: "100%" }}>
                    <ListItem>
                        <Stack direction="column">
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                <Typography>Necessary cookies (Always Active)</Typography>
                                <Switch checked={true} />
                            </Stack>
                            <Typography sx={{ paddingLeft: '2rem' }}>Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.</Typography>
                        </Stack>
                    </ListItem>
                    <ListItem>
                        <Stack direction="column">
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                <Typography>Performance cookies</Typography>
                                <Switch
                                    checked={nextPolicy.performance}
                                    onChange={(e) => {
                                        setNextPolicy({ ...nextPolicy, performance: e.target.checked })
                                    }}
                                />
                            </Stack>
                            <Typography sx={{ paddingLeft: '2rem' }}>Performance cookies log information about the performance of the apps on your browser. We use this to debug problems with the apps, and make decisions about how to improve the experiences in the future. Rejecting these cookies will prevent us from monitoring how the website performs.</Typography>
                        </Stack>

                    </ListItem>
                    <ListItem>
                        <Stack direction="column">
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                <Typography>Functional cookies</Typography>
                                <Switch
                                    checked={nextPolicy.functional}
                                    onChange={(e) => setNextPolicy({ ...nextPolicy, functional: e.target.checked })}
                                />
                            </Stack>
                            <Typography sx={{ paddingLeft: '2rem' }}>Functional cookies enable the website to provide more personalized experiences. These cookies may be set by us or by third party providers. Rejecting these cookies can break some of these services.</Typography>
                        </Stack>

                    </ListItem>
                </List>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={() => {
                        setPolicy(nextPolicy)
                        markConfirmed();
                        flashAlert();
                    }}>Confirm selection</Button>
                    <Button variant="contained" onClick={() => {
                        setNextPolicy({
                            performance: true,
                            functional: true
                        })
                        acceptAll();
                        markConfirmed();
                        flashAlert();
                    }}>Accept all</Button>
                    <Button variant="contained" onClick={() => {
                        setNextPolicy({
                            performance: false,
                            functional: false
                        })
                        necessaryOnly();
                        markConfirmed();
                        flashAlert();
                    }}>Necessary only</Button>
                </Stack>

                {
                    showAlert && <Alert severity="success">Cookie settings saved</Alert>
                }
            </Stack>
        </Container>
    )
}