
import React, { useMemo } from 'react';
import { useAuth } from '../services/useAuth';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography/Typography';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Button from '@mui/material/Button/Button';

export const ManageAccount = () => {

    const { user, loading } = useAuth();

    const navigate = useNavigate();

    if (loading) {
        return <CircularProgress />
    }

    if (!user) {
        return <Typography variant="h3">You are not logged in</Typography>
    }

    return (
        <Container>
            <Stack direction="column" spacing={2}>
                <Typography variant="h6">Manage Account</Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Email" secondary={user.email} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Name" secondary={user.username} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Google'} secondary={user.links.googleId ? 'Connected' : 'Not Connected'} />
                    </ListItem>
                </List>

                <Typography variant="h6">Security</Typography>
                <Stack direction="row" spacing={2}>
                    <Button variant="text" color="primary" onClick={() => {
                        navigate('/change-password');
                    }}>Change Password</Button>
                </Stack>
            </Stack>

        </Container>
    )
}