import axios, { AxiosError } from 'axios';
import { atom, createStore, Provider } from 'jotai';
import { store } from './main.service';
import { User } from '../types/Account';

const authAtom = atom<User | null>(null);

export class AuthService {
    private static _user: User | null = null;
    private static client = axios.create({
        baseURL: '/api/auth'
    })

    static get store() {
        return store;
    }

    static get atom() {
        return authAtom;
    }

    static get user() {
        return this._user;
    }

    static dataToUser(data: any): User | undefined {

        let user: User = {
            guid: '',
            username: '',
            email: '',
            links: {
                googleId: ''
            },
            isAdmin: false
        }

        if (data.guid && typeof data.guid === 'string') {
            user.guid = data.guid;
        }

        if (data.username && typeof data.username === 'string') {
            user.username = data.username;
        }

        if (data.email && typeof data.email === 'string') {
            user.email = data.email;
        }

        if (data.links && typeof data.links === 'object') {
            if (data.links.googleId && typeof data.links.googleId === 'string') {
                user.links.googleId = data.links.googleId;
            }
        }

        if (data.isAdmin && typeof data.isAdmin === 'boolean') {
            user.isAdmin = data.isAdmin;
        }

        if (user.guid) {
            return user;
        }

        return undefined;
    }

    // authenticated a user
    static async signin(data: { email: string, password: string }) {
        try {
            const response = await this.client.post('signin', data)
            const _user = this.dataToUser(response.data);
            if (_user) {
                this._user = _user;
                store.set(authAtom, () => _user);
                return this._user;
            } else {
                store.set(authAtom, () => null);
                throw new Error('Response is not a valid user object', response.data);
            }
        } catch (e) {
            const eAxiosError = e as AxiosError

            this._user = null;
            store.set(authAtom, () => null);

            if (eAxiosError.response?.status === 500
                && typeof eAxiosError.response?.data === "string"
                && eAxiosError.response?.data.includes("User account did not use password to sign up")) {
                throw new Error('User account did not use password to sign up');
            }

            throw new Error('Failed to authenticate ' + data.email);
        }
    }

    // signup
    static async signupByEmail(data: { email: string, password: string, username: string }) {
        try {
            const response = await this.client.post('signup/email', data)
            const _user = this.dataToUser(response.data);
            if (_user) {
                this._user = _user;
                store.set(authAtom, () => _user);
                return this._user;
            } else {
                store.set(authAtom, () => null);
                throw new Error('Response is not a valid user object', response.data);
            }
        } catch (e) {
            this._user = null;
            store.set(authAtom, () => null);
            const eAxiosError = e as AxiosError
            if (eAxiosError.response?.data) {
                const { message } = eAxiosError.response.data as { message: string };
                throw new Error(message)
            }

            if (e as { message: string }["message"]) {
                console.log(e);
                throw e;
            }

            throw new Error('Failed to authenticate ' + data.email);
        }
    }

    // signin with google
    static async signinOneTap(credential: any) {
        try {
            const response = await this.client.post('one-tap/callback', {
                credential
            })
            const _user = this.dataToUser(response.data);
            if (_user) {
                this._user = _user;
                store.set(authAtom, () => _user);
                return this._user;
            } else {
                store.set(authAtom, () => null);
                throw new Error('Response is not a valid user object', response.data);
            }
        } catch (e) {
            this._user = null;
            store.set(authAtom, () => null);
            throw new Error('Failed to authenticate with Google One Tap');
        }
    }

    // check
    static async checkProfile() {
        const response = await this.client.get('me')
        const _user = this.dataToUser(response.data);
        if (_user) {
            this._user = _user;
            store.set(authAtom, () => _user);
            window.google.accounts.id.cancel();
            return this._user;
        } else {
            store.set(authAtom, null);
            throw new Error('Response is not a valid user object', response.data);
        }
    }

    // logout
    static async logout() {
        await this.client.get('logout')
        store.set(authAtom, null);
        return true;
    }

    // change password
    static async changePassword(data: { oldPassword: string, newPassword: string }) {
        try {
            await this.client.post('change_password', data)
            return true;
        } catch (e) {
            throw new Error('Failed to change password');
        }
    }



    // forgot password request
    static async forgotPasswordRequest(email: string) {
        try {
            await this.client.post('forgot_password', {
                email
            })
            return true;
        } catch (e) {
            throw new Error('Failed to send reset link');
        }
    }

    // forgot password confirm
    static async forgotPasswordConfirm(code: string, newPassword: string) {
        try {
            await this.client.post('forgot_password_confirm', {
                code,
                newPassword
            })
            return true;
        } catch (e) {
            throw new Error('Failed to send reset link');
        }
    }
}