
const ENVIRONMENTS: { [key: string]: 'development' | 'staging' | 'production' } = {
    localhost: 'development',
    "dsolver.ca": 'staging',
    "persera.ca": 'production',
}

const hostname = location.hostname.toLowerCase();

const host = Object.keys(ENVIRONMENTS)
    .find(key => hostname.includes(key));

export const environment = ENVIRONMENTS[host ?? 'localhost'];