
import React from 'react';
import { cookieBannerAtom, useCookies } from '../../utils/useCookies';
import { useAtom } from 'jotai';
import Fade from '@mui/material/Fade/Fade';
import Paper from '@mui/material/Paper/Paper';
import Stack from '@mui/material/Stack/Stack';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';


export const CookieBanner = () => {

    const { showCookieBanner, acceptAll, necessaryOnly, closeBanner } = useCookies();

    return (
        <Fade appear={false} in={showCookieBanner}>
            <Paper
                role="dialog"
                aria-modal="false"
                aria-label="Cookie banner"
                square
                variant="outlined"
                tabIndex={-1}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    m: 0,
                    p: 2,
                    borderWidth: 0,
                    borderTopWidth: 1,
                }}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    gap={2}
                >
                    <Box
                        sx={{
                            flexShrink: 1,
                            alignSelf: { xs: 'flex-start', sm: 'center' },
                        }}
                    >
                        <Typography fontWeight="bold">This website uses cookies</Typography>
                        <Typography variant="body2">
                            We use cookies to improve user experience, and analyze website traffic.
                        </Typography>
                    </Box>
                    <Stack
                        gap={2}
                        direction={{
                            xs: 'row-reverse',
                            sm: 'row',
                        }}
                        sx={{
                            flexShrink: 0,
                            alignSelf: { xs: 'flex-end', sm: 'center' },
                        }}
                    >
                        <Button size="small" onClick={() => {
                            acceptAll();
                            closeBanner();
                        }} variant="contained">
                            Allow all
                        </Button>
                        <Button size="small" onClick={() => {
                            necessaryOnly();
                            closeBanner();
                        }} variant="contained" color='secondary'>
                            Necessary Only
                        </Button>
                        <Button size="small" href={'/cookies'} target={'_blank'} variant='outlined' color='info'>Customize cookies</Button>
                    </Stack>
                </Stack>
            </Paper>
        </Fade >
    )
}