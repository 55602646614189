import Container from '@mui/material/Container/Container';
import React from 'react';

export const About = () => {

    return (
        <Container>

        </Container>
    )
}